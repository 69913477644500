import React, { PureComponent } from 'react'
import get from 'lodash/get'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Link from 'gatsby-link'
import PageLayout from '../components/layouts/PageLayout'
import { AZFilterList } from '../components/common'
import Breadcrumb from '../components/common/Breadcrumb'
import { buildURL, CONTENTFUL_GRAPHQL_TYPES, SECTIONS } from '../utils/url'

import '../assets/styles/guideline/_browseGuidelines.scss'
import * as SearchHelper from '../helpers/SearchHelper'
import IconStar from '../components/common/icons/Star'

export default class Browse extends PureComponent {
    constructor(props) {
        super(props)

        let allContents = []
        if (typeof window !== 'undefined') {
            allContents = JSON.parse(localStorage.getItem('bookmarks')) || []
            if (allContents.length > 0) {
                allContents = allContents.map(allContent => {
                    allContent.isBookmarked = true
                    return allContent
                })
            }
        }

        this.state = {
            guidelines: allContents,
            type: null,
            category: null,
            index: null,
        }
    }

    static getDerivedStateFromProps(props, state) {
        const queryParams = SearchHelper.queryParamsToObject(props.location.search)

        const index = get(queryParams, 'index')
        const type = get(queryParams, 'type')
        const category = get(queryParams, 'cat')

        return {
            type,
            category,
            index,
            guidelines: Browse.filterGuidelines(null, state.guidelines),
        }
    }

    onItemHovered = (item, isHovered) => {
        const guidelines = this.state.guidelines
        const hoverIndex = guidelines.findIndex(guideline => guideline.slug === item.slug)
        guidelines[hoverIndex].isHovered = isHovered
        this.setState({ guidelines })
    }

    onStarHovered = (item, isStarHovered) => {
        const guidelines = this.state.guidelines
        const hoverIndex = guidelines.findIndex(guideline => guideline.slug === item.slug)
        guidelines[hoverIndex].isStarHovered = isStarHovered
        this.setState({ guidelines })
    }

    onBookmark = (item) => {
        const bookmarks = JSON.parse(localStorage.getItem('bookmarks')) || []
        const guidelines = this.state.guidelines
        const bookmarkIndex = bookmarks.findIndex(bookmark => bookmark.slug === item.slug)
        const index = guidelines.findIndex(guideline => guideline.slug === item.slug)
        bookmarks.splice(bookmarkIndex, 1)
        guidelines.splice(index, 1)
        this.setState({ guidelines })
        localStorage.setItem('bookmarks', JSON.stringify(bookmarks))
    }

    static filterGuidelines = (serviceProvider, guidelines) => {
        return guidelines.filter(guideline => {
            if (serviceProvider) {
                return guideline.serviceProvider && guideline.serviceProvider.slug === serviceProvider.slug
            }

            return true
        })
    }

    render() {
        const { guidelines, type, category, index } = this.state
        let sitemap = get(this.props, 'data.allContentfulWidgetSitemap.edges', [])
        sitemap = sitemap.map(link => link && link.node)

        return (
            <PageLayout className="guideline-list shortlist" hideSocials sitemap={sitemap} activeSection={SECTIONS.HEALTH_PROFESSIONALS.key}>
                <Helmet title="Starship - Clinical Guidelines Shortlist" />
                <Breadcrumb page={{ title: 'My Shortlist', slug: 'my-shortlist', __typename: CONTENTFUL_GRAPHQL_TYPES.GUIDELINE, userSection: SECTIONS.GUIDELINES.key }} />
                <main> 
                    <h1 className="page-title">My Shortlist</h1>
                    <div className="guideline content wrapper">
                        <AZFilterList
                            items={guidelines}
                            itemKey="title"
                            showTypeFilter
                            selectedServiceProvider={null}
                            selectedType={type}
                            selectedCategory={category}
                            selectedIndex={index}
                            onItemHover={(elt) => this.onItemHovered(elt, true)}
                            onItemLeave={(elt) => this.onItemHovered(elt, false)}
                            emptyTextComponent={<p className={'no-result'}>Your shortlist is empty. Use the <i className="fas fa-star icon"></i> icon next to an item in the search results or in the toolbar on the guideline to save your pages for the next time you visit.</p>}
                            renderComponent={item => {
                                let url = buildURL(item, item.guidelineType && item.guidelineType.guidelineType === 'Calculator' ? CONTENTFUL_GRAPHQL_TYPES.CALCULATOR : CONTENTFUL_GRAPHQL_TYPES.GUIDELINE)
                                if (item.searchRedirectUrl) {
                                    url = item.searchRedirectUrl
                                    return <div className="index-item">
                                        <a href={url} target="_blank" rel="noopener noreferrer">{item.title}</a>
                                        { item.isHovered || item.isBookmarked ? <span className="shortlist-star" onClick={() => this.onBookmark(item)} onMouseEnter={() => this.onStarHovered(item, true)} onMouseLeave={() => this.onStarHovered(item, false)}>
                                             <IconStar fill={item.isStarHovered || item.isBookmarked ? '#485C97' : '#fff' } />
                                        </span> : <></> }
                                    </div>
                                }
                                return <div className="index-item">
                                    <Link to={url}>{item.title}</Link>
                                    {item.isHovered || item.isBookmarked ? <span className="shortlist-star" onClick={() => this.onBookmark(item)}  onMouseEnter={() => this.onStarHovered(item, true)} onMouseLeave={() => this.onStarHovered(item, false)}>
                                        <IconStar fill={item.isStarHovered || item.isBookmarked ? '#485C97' : '#fff' } />
                                    </span> : <></>}
                                </div>
                            }}
                        />
                    </div>
                </main>
            </PageLayout>
        )
    }
}

export const pageQuery = graphql`
    query GuidelineIndexQueryShortlist {
        allContentfulWidgetSitemap {
            edges {
                node {
                    contentful_id
                    userSection
                    title
                    slug
                    links {
                        __typename
                        ... on ContentfulPageCustom {
                            slug
                            title
                            contentful_id
                            userSection
                        }
                        ... on ContentfulPageDirectoryOfServices {
                            id
                            title
                        }
                        ... on ContentfulPageDonation {
                            slug
                            title
                            contentful_id
                        }
                        ... on ContentfulPageGeneric {
                            slug
                            title
                            userSection
                        }
                        ... on ContentfulPageGroup {
                            slug
                            title
                            userSection
                        }
                        ... on ContentfulPageGuideline {
                            title
                            slug
                        }
                        ... on ContentfulPageWidgets {
                            title
                            slug
                            userSection
                        }
                    }
                }
            }
        }
    }
`
